/**
 * @flow
 */

import React from "react";
import type { Element, Node } from "react";

import { FontAwesomeIcon } from "./FontAwesomeIcon";
import { buttonTypeMap, type ButtonType } from "../types/bulmaButtonTypes";

const sizeMap = {
  small: "is-small",
  normal: "is-normal",
  medium: "is-medium",
  large: "is-large",
};

type Size = $Keys<typeof sizeMap>;

// Since this is our base button, it's pretty realistic that we'll want to define other props on the button
// besides what we've thought of here (event handlers, for instance). As such, lets not use a flow exact type
// definition, so that consumers can pass whatever extra props they want.
export type Props = {|
  id?: string,
  title: string,
  focused?: boolean,
  outlined?: boolean,
  size?: Size,
  disabled?: boolean,
  loading?: boolean,
  textStyle?: "italic" | "bold" | "normal",
  type?: ButtonType,
  // Supply one of: [onPress, href]. We error if both are supplied.
  onPress?: ?() => void,
  href?: ?string,
  iconColor?: string,
  leftIcon?: Element<typeof FontAwesomeIcon>,
  rightIcon?: Element<typeof FontAwesomeIcon>,
|};

type HrefWrapperProps = {
  classes: Array<string>,
  attributes: { [string]: any },
  children?: Node,
  href: ?string,
};

type OnPressWrapperProps = {
  classes: Array<string>,
  attributes: { [string]: any },
  children?: Node,
  onPress: ?() => void,
};

type NoActionWrapperProps = {
  classes: Array<string>,
  attributes: { [string]: any },
  children?: Node,
};

const buttonStyle = {
  height: "auto",
  maxWidth: "100%",
};

const OnPressButton = React.forwardRef<OnPressWrapperProps, HTMLButtonElement>((props, ref) => {
  // eslint-disable-next-line i18next/no-literal-string
  const defaultClasses = ["button"];
  const allClasses = defaultClasses.concat(props.classes.filter(Boolean)).join(" ");
  return (
    <button
      {...props.attributes} // eslint-disable-line react/jsx-props-no-spreading
      ref={ref}
      style={{ ...buttonStyle }}
      type="button"
      className={allClasses}
      onClick={props.onPress}
    >
      {props.children}
    </button>
  );
});

const HrefButton = React.forwardRef<HrefWrapperProps, HTMLAnchorElement>((props, ref) => {
  // eslint-disable-next-line i18next/no-literal-string
  const defaultClasses = ["button"];
  const allClasses = defaultClasses.concat(props.classes.filter(Boolean)).join(" ");
  return (
    <a
      {...props.attributes} // eslint-disable-line react/jsx-props-no-spreading
      ref={ref}
      style={buttonStyle}
      className={allClasses}
      href={props.href}
      role="button"
    >
      {props.children}
    </a>
  );
});

const NoActionButton = React.forwardRef<NoActionWrapperProps, HTMLButtonElement>((props, ref) => {
  // eslint-disable-next-line i18next/no-literal-string
  const defaultClasses = ["button"];
  const allClasses = defaultClasses.concat(props.classes.filter(Boolean)).join(" ");
  return (
    <button
      {...props.attributes} // eslint-disable-line react/jsx-props-no-spreading
      ref={ref}
      style={buttonStyle}
      className={allClasses}
      type="button"
    >
      {props.children}
    </button>
  );
});

export const Button: React$AbstractComponent<Props, any> = React.forwardRef<Props, *>(
  (
    {
      id,
      title,
      focused = false,
      outlined = false,
      size = "normal",
      disabled = false,
      loading = false,
      textStyle = "normal",
      type = "default",
      onPress,
      href,
      leftIcon,
      rightIcon,
    },
    ref
  ) => {
    const attributes = {
      disabled,
    };
    const classes = [];

    classes.push(sizeMap[size]);
    classes.push(focused ? "is-focused" : "");
    classes.push(loading ? "is-loading" : "");
    classes.push(outlined ? "is-outlined" : "");

    // These overrides are not meant to be publicly accessible, they're meant to be used to alter the button styling
    // for specific button 'types' and should be used sparingly.
    if (type === "ghost-primary") {
      // We should implement a better more permanent solution to ghost buttons that look like this, but we currently
      // need more insight from product on using 'link' styling for buttons in various contexts. This works for now.
      classes.push("has-text-primary has-text-weight-bold is-borderless px-1");
      // The primary ghost button is often used inline with text, so lets remove its default padding. Users of this
      // style of button can use padding and margin on the surrounding elements to style this appropriately.
    }

    const children = (
      <>
        {leftIcon}
        <span
          style={{
            maxWidth: "100%",
            whiteSpace: "normal",
            fontStyle: textStyle !== "normal" ? textStyle : undefined,
          }}
        >
          {title}
        </span>
        {rightIcon}
      </>
    );
    if (!disabled) {
      const isLink = href != null;
      const isOnPress = onPress != null;
      classes.push(buttonTypeMap[type]);
      if (isLink && !isOnPress) {
        return (
          <HrefButton id={id} ref={ref} attributes={attributes} classes={classes} href={href}>
            {children}
          </HrefButton>
        );
      }
      if (isOnPress && !isLink) {
        return (
          <OnPressButton id={id} ref={ref} attributes={attributes} classes={classes} onPress={onPress}>
            {children}
          </OnPressButton>
        );
      }
    }
    // Even if the button is disabled still push the correct class, so our disabled styling is correct.
    classes.push(buttonTypeMap[type]);

    // If there's no link or action, we render a disabled button. We used to crash, but that's
    // a harder to pin-down issue then a non-functional button, so show this instead.
    // Note that disabling a button is not enough, since browsers will still allow it to be clicked. So we
    // will return this NoActionButton to prevent the button from being clickable.
    return (
      <NoActionButton
        id={id}
        ref={ref}
        attributes={{
          ...attributes,
          disabled: true,
        }}
        classes={classes}
      >
        {children}
      </NoActionButton>
    );
  }
);

// We need a display name because this is a HoC that uses React.forwardRef.
// eslint-disable-next-line i18next/no-literal-string
Button.displayName = "Button";
