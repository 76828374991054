/**
 * @flow
 */

import React from "react";
import type { Element, Node } from "react";

import { AlertModal } from "./AlertModal";
import { type FontAwesomeIconType } from "./FontAwesomeIcon";

export type AlertConfirmationButtonsBuilderType = (hideAlertFn: () => void) => Array<Element<*>>;
export type AlertConfirmationProps = {
  getApplicationNode?: () => ?HTMLElement,
  children?: (isAlertOpen: boolean, showAlertFn: () => void) => Node,
  modalTitle: string,
  openByDefault?: boolean,
  modalMessage?: ?string,
  modalBody?: Node,
  buttonsBuilder: AlertConfirmationButtonsBuilderType,
  icon?: ?FontAwesomeIconType,
  iconColor?: string,
};

type AlertConfirmationState = { isAlertOpen: boolean };

/**
 * This alert confirmation window accepts render props for its children and the buttons displayed within. As such,
 * this component is meant as a basis for creating other more context-specific alerts on-top-of. It's unlikely you'd
 * want to use this directly, unless you're building an alert confirmation dialog. See:
 * 'SimpleAlertConfirmation.jsx' or 'DestroyAlertConfirmation.jsx' for examples.
 */
export class AlertConfirmation extends React.Component<AlertConfirmationProps, AlertConfirmationState> {
  constructor(props: AlertConfirmationProps) {
    super(props);
    this.state = { isAlertOpen: props.openByDefault || false };
  }

  showAlert: () => void = () => this.setState({ isAlertOpen: true });

  hideAlert: () => void = () => this.setState({ isAlertOpen: false });

  render(): Node {
    return (
      <div>
        {this.props.children && this.props.children(this.state.isAlertOpen, this.showAlert)}
        {this.state.isAlertOpen && (
          <AlertModal
            getApplicationNode={this.props.getApplicationNode}
            title={this.props.modalTitle}
            icon={this.props.icon}
            iconColor={this.props.iconColor}
            message={this.props.modalMessage}
            buttonsBuilder={() => this.props.buttonsBuilder(this.hideAlert)}
          >
            {this.props.modalBody}
          </AlertModal>
        )}
      </div>
    );
  }
}
