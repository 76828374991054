/**
 * @flow
 */
import React, { useState, type Element } from "react";
import {
  requestIgnoreNoPreferredSiteLanguageWarning,
  selectedLanguageCodeURL,
} from "../../../../common/webpack/jsApps/i18nAndl10nUtilities";
import { FontAwesomeIcon } from "../../../../common/webpack/shared/components/FontAwesomeIcon";
import { WarningAlertConfirmation } from "../../../../common/webpack/shared/components/WarningAlertConfirmation";

type Props = {
  languages: Array<[string, string]>,
  initialLanguageCode: string,
  userIsAuthenticated: boolean,
  shouldPromptUserToSetSessionLanguage: boolean,
};

const App = ({
  languages,
  initialLanguageCode,
  userIsAuthenticated,
  shouldPromptUserToSetSessionLanguage,
}: Props): Element<*> => {
  const initialLanguageName = languages.find((l) => l[0] === initialLanguageCode)?.[1];
  const [selectedLanguageCode, setSelectedLanguageCode] = useState<string>(initialLanguageCode);
  const setLanguage = () => {
    // redirect to localize to change the employee's language
    window.location.href = selectedLanguageCodeURL(selectedLanguageCode);
  };
  const ignoreNoPreferredSiteLanguageWarning = () => {
    if (shouldPromptUserToSetSessionLanguage) {
      requestIgnoreNoPreferredSiteLanguageWarning();
    }
  };

  return (
    <WarningAlertConfirmation
      warningButtonAction={setLanguage}
      modalTitle={window.gettext("Set your Preferred Language")}
      warningButtonLabel={window.pgettext(
        "The button label for confirming the user's preferred language based on a selected radio button choice.",
        "Set Language"
      )}
      cancelButtonAction={ignoreNoPreferredSiteLanguageWarning}
      openByDefault={shouldPromptUserToSetSessionLanguage}
      modalBody={
        <div style={{ paddingLeft: 27 }}>
          {userIsAuthenticated && (
            <p className="mb-1 is-size-6">
              {window.gettext("This change will also be applied to your notification language preference.")}
            </p>
          )}
          {languages.map(([languageCode, languageName]) => (
            <div key={languageCode} className="mb-1 is-flex is-align-items-center">
              <input
                type="radio"
                aria-labelledby={`radio-input-${languageCode}`}
                id={languageCode}
                name="preferred-languages"
                value={languageCode}
                checked={languageCode === selectedLanguageCode}
                onChange={(e) => setSelectedLanguageCode(e.target.value)}
              />
              <label id={`radio-input-${languageCode}`} htmlFor={languageCode} className="ml-2">
                {languageName}
              </label>
            </div>
          ))}
        </div>
      }
    >
      {(isAlertOpen, showAlertFn) => (
        <div className="is-flex is-justify-content-center is-align-items-center">
          <FontAwesomeIcon
            icon={{
              style: "solid",
              name: "globe",
            }}
            size={1}
          />
          <label htmlFor="language-picker-button" className="ml-1">
            {" "}
            {window.pgettext("The label for displaying what langauge the user has selected.", "Language:")}
          </label>
          <button
            id="language-picker-button"
            type="button"
            className="button is-ghost p-0 pl-1 small has-text-weight-bold"
            style={{ height: "1em" }}
            onClick={() => showAlertFn()}
          >
            {initialLanguageName}
          </button>
        </div>
      )}
    </WarningAlertConfirmation>
  );
};

export default App;
