/**
 * @flow
 */

export const buttonTypeMap = {
  default: "",
  primary: "is-primary",
  dark: "is-dark",
  info: "is-info",
  success: "is-success",
  warning: "is-warning",
  danger: "is-danger",
  light: "is-light",
  text: "is-text",
  "ghost-primary": "is-ghost",
};

export type ButtonType = $Keys<typeof buttonTypeMap>;
