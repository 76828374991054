/**
 * @flow
 */

import React from "react";
import type { Element, Node } from "react";
import AriaModal from "react-aria-modal";

import { Button } from "./Button";
import { type ButtonType } from "../types/bulmaButtonTypes";
import { FontAwesomeIcon, type FontAwesomeIconType } from "./FontAwesomeIcon";

type ButtonProps = {
  type?: ButtonType,
  title: string,
  action: () => void,
  disabled?: boolean,
};

type AlertModalProps = {|
  getApplicationNode?: () => ?HTMLElement,
  title?: ?string,
  message?: ?string,
  subnote?: ?string,
  // Supply one of: [buttonsProps, buttonsBuilder].
  // buttonsBuilder takes priority and buttonsProps are ignored if both are supplied.
  buttonsProps?: Array<ButtonProps>,
  // The returned buttons needs react 'key' properties defined. I'm not sure how to enforce that with Flow :/
  buttonsBuilder?: () => Array<Element<*>>,
  icon?: ?FontAwesomeIconType,
  iconColor?: ?string,
  children?: Node,
|};

const buttonsFromButtonProps = (buttonProps: Array<ButtonProps>) =>
  buttonProps.map((button) => (
    <Button
      type={button.type}
      key={button.title}
      title={button.title}
      onPress={button.action}
      disabled={button.disabled}
    />
  ));

const renderButtons = (buttons: Array<Element<*>>) =>
  buttons.map((button) => (
    <div style={{ marginLeft: 10, marginTop: 10 }} key={button.key}>
      {button}
    </div>
  ));

export const AlertModal = (props: AlertModalProps): Node => {
  return (
    <AriaModal
      verticallyCenter
      titleText={props.title}
      getApplicationNode={
        props.getApplicationNode != null ? props.getApplicationNode : () => document.getElementById("react")
      }
    >
      <div
        style={{
          maxWidth: "93%",
          width: 650,
          backgroundColor: "white",
          paddingTop: 30,
          paddingBottom: 30,
          paddingLeft: 40,
          paddingRight: 40,
          borderRadius: 5,
          borderWidth: 1,
          margin: "auto",
        }}
      >
        <div>
          {props.title && (
            <h1 className="is-size-4 has-text-weight-bold mb-4">
              {props.icon && (
                <span className="mr-2">
                  <FontAwesomeIcon icon={props.icon} size={1} style={{ color: props.iconColor }} />
                </span>
              )}
              {props.title}
            </h1>
          )}
          {props.message && <p className="mb-1 is-size-6">{props.message}</p>}
          {props.subnote && <p className="mb-1 is-size-7">{props.subnote}</p>}
          {props.children && <div className="mt-2 mb-1">{props.children}</div>}
        </div>

        <div
          className="is-flex mt-4"
          style={{
            flexWrap: "wrap",
            flexDirection: "row-reverse",
          }}
        >
          {props.buttonsBuilder
            ? renderButtons(props.buttonsBuilder())
            : renderButtons(buttonsFromButtonProps(props.buttonsProps || []))}
        </div>
      </div>
    </AriaModal>
  );
};
