/**
 * @flow
 */

import React from "react";
import ReactDOM from "react-dom";
import { decodeBoolean, decodeObject, decodeString } from "../../../../common/webpack/shared/utils/decode";

import App from "./App";

const dataContainer: ?HTMLElement = document.getElementById("authenticated-language-picker");

if (dataContainer) {
  const languages = decodeObject(dataContainer.dataset.languages);
  const initialLanguageCode = decodeString(dataContainer.dataset.initialLanguageCode);
  const userIsAuthenticated = decodeBoolean(dataContainer.dataset.userIsAuthenticated);
  const shouldPromptUserToSetSessionLanguage = decodeBoolean(
    dataContainer.dataset.shouldPromptUserToSetSessionLanguage
  );
  ReactDOM.render(
    <App
      languages={languages}
      initialLanguageCode={initialLanguageCode}
      userIsAuthenticated={userIsAuthenticated}
      shouldPromptUserToSetSessionLanguage={shouldPromptUserToSetSessionLanguage}
    />,
    dataContainer
  );
}
